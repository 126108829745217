<template>
  <div class="share">
    <back style="z-index: 1;"></back>
    <div class="up">
      <div class="wrap">
        <div style="height: 100%;width: 100%;position: relative">
          <swiper class="swiper-container1" :options="option" ref="mySwiper">
            <swiper-slide v-for="(item, index) in poster" :key="index">
              <div class="poster">
                <img :src="item" width="100%" height="100%" v-if="item"/>
                <div v-else class="wait">
                  <van-loading size="24px">正在生成海报...</van-loading>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <swiper class="swiper-container2" :options="option" ref="mySwiper">
            <swiper-slide v-for="(item, index) in posterList" :key="index">
              <div class="create" id="create" ref="poster">
                <div class="create-poster">
                  <img :src="item" width="100%" height="100%"/>
                  <div class="poster2 white">
                    轻松小镇核心玩法道具 永不增发
                    <br>
                    后续将成为小镇内土地解锁资格凭证
                  </div>
                </div>
                <div class="info">
                  <div class="left">
                    <img :src="user.avatar !== '0' ? user.avatar : require('../assets/img/user.png') " class="avatar"/>
                    <div style="display: flex;justify-content: center;flex-direction: column;">
                      <div class="m-b-5">{{user.nickname}}</div>
                      <div class="grey f-12">邀请您共同入住轻松小镇数字空间</div>
                    </div>
                  </div>
                  <vue-qr :text="url" class="qr-code" :margin="0" :size="50"></vue-qr>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="down p-30">
      <div class="f-17 text-center m-b-5">长按图片转发或保存</div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import Back from '../components/Back'
import { share, wxConfig } from '../api/user'
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getWxConfig } from '../../tool'
// let vm
// menuItem:share:appMessage
// menuItem:share:timeline
export default {
  name: 'Share',
  components: { SwiperSlide, Swiper, Back, vueQr },
  data () {
    return {
      option: {
        slidesPerView: 1.2,
        centeredSlides: true,
        touchRatio: 1, //  触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination'
        }
        // on: {
        //   slideChange () {
        //     vm.index = this.activeIndex
        //   },
        //   init () {
        //     vm.index = this.activeIndex
        //   }
        // }
      },
      activityId: null,
      userId: null,
      url: '',
      poster: [''],
      posterList: [
        require('../assets/img/seed-share.jpg')
      ],
      count: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    // vm = this
    getWxConfig('轻松小镇', '创世种子限量发售')
  },
  mounted () {
    this.getShareConfig()
  },
  methods: {
    async getShareConfig () {
      try {
        const res = await share({})
        this.activityId = res.data.reserve_id
        this.userId = res.data.user_id
        this.count = res.data.count
        this.url = `${window.location.origin}/?invited_id=${this.userId}&activity_id=${this.activityId}#/gram`
        // this.url = `${window.location.origin}/?from_id=${this.userId}#/`
        // localStorage.setItem('invited_id', res.data.user_id)
        localStorage.setItem('activity_id', res.data.reserve_id)
        localStorage.setItem('count', res.data.count)
        this.createPoster()
      } catch (e) {
        console.log(e)
      }
    },
    createPoster () {
      this.$refs.poster.forEach((item, index) => {
        console.log(item)
        setTimeout(() => {
          html2canvas(item, {
            width: parseFloat(item.parentNode.style.width),
            backgroundColor: null, // null 表示设置背景为透明色
            useCORS: true, // 是否尝试使用CORS从服务器加载图像
            allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
            taintTest: true, // 是否在渲染前测试图片
            scale: 3, // dpr比列
            scrollY: 0 // 截屏时页面滚动，造成截屏图片不全或空白
          }).then(canvas => {
            const img = canvas.toDataURL('image/png')
            this.$set(this.poster, index, img)
          }).catch(e => {
            console.log(e)
            this.$toast.fail('生成海报失败')
          })
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .share{
    height: 100vh;
    overflow: hidden;
    .up{
      height: 85vh;
      background: url("../assets/img/user-bg.png") no-repeat;
      background-size: cover;
      .wrap{
        height: 100%;
        width: 100%;
        padding: 63px 0 30px;
        background-color: rgba(0, 0, 0, 0.48);
        position: relative;
        .swiper-container1{
          height: 100%;
          .swiper-slide{
            border-radius: 3px;
            overflow: hidden;
            height: 100%;
            background-color: black;
            .poster{
              background-color: white;
              border-radius: 3px;
              height: 100%;
              width: 100%;
              .wait{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
              }
            }
          }
          .swiper-slide:not(.swiper-slide-active) {
            transition: 400ms;
            transform: scale(0.9);
          }
        }
        .swiper-container2{
          position: absolute;
          height: 100%;
          left: 0;
          width: 100%;
          top: -10000px;
          .swiper-slide{
            .create{
              background-color: white;
              height: 100%;
              width: 100%;
              .create-poster{
                height: 85%;
                width: 100%;
                position: relative;
                .poster2{
                  position: absolute;
                  bottom: 30px;
                  width: 100%;
                  left: 20px;
                  line-height: 18px;
                }
                .poster3{
                  position: absolute;
                  top: 90px;
                  width: 100%;
                }
              }
              .info{
                height: 15%;
                display: flex;
                padding: 10px;
                justify-content: space-between;
                align-items: center;
                .left{
                  display: flex;
                  .avatar{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                  }
                }
                .qr-code{

                }
              }
            }
          }
        }
        .swiper-pagination{
          width: 100%;
          margin: 20px 0;
        }
        .pagination{
          .swiper-pagination-bullet{
            background-color: #000000;
            margin-right: 20px;
            &:last-child{
              margin-right: 0;
            }
          }
          .swiper-pagination-bullet-active{
            opacity: 0.3;
            background-color: #000000;
          }
        }
      }
    }
  }
</style>
